:root {
  --primary-color: #5f2f5f;
  --secondary-color: #fc99f3;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
  --dark-color: #333;
  --text-muted: #797777;
  --font-family: 'Readex Pro', sans-serif;
  --font-size-base: 1rem;
  --font-size-large: 1.5rem;
  --font-size-small: 0.875rem;
  --border-radius: 10px;
  --transition: 0.3s ease;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--light-color);
  font-family: var(--font-family);
  position: relative;
}

/* Chat Header */
.chat-header {
  background-color: var(--primary-color);
  color: var(--light-color);
  padding: 1rem;
  text-align: center;
  font-size: var(--font-size-large);
  border-bottom: 3px solid var(--tertiary-color);
}

/* Chat Sidebar */
.chat-sidebar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--primary-color);
  padding: 10px;
  border-bottom: 2px solid var(--tertiary-color);
  position: sticky;
  top: 0;
  z-index: 10;
}

.sidebar-tab {
  padding: 10px 15px;
  background: var(--secondary-color);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--font-size-base);
  font-weight: bold;
  color: var(--light-color);
  transition: background var(--transition);
}

.sidebar-tab.active {
  background: var(--tertiary-color);
  color: var(--light-color);
}

.sidebar-tab:hover {
  background: var(--primary-color);
}

/* Chat Main Content */
.chat-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-color);
}

/* Loading Spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid var(--primary-color);
  border-top-color: var(--secondary-color);
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.no-data {
  font-size: 1.2rem;
  color: var(--secondary-color);
  text-align: center;
}

.no-data a {
  color: var(--tertiary-color);
  font-weight: bold;
  text-decoration: none;
}

.no-data a:hover {
  text-decoration: underline;
}

/* Matches Display Adjustments */
.matches-display {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

/* Larger Screen Adjustments */
@media (min-width: 1024px) {
  .chat-container {
    flex-direction: row;
  }

  .chat-sidebar {
    flex: 0.2;
    flex-direction: column;
    align-items: stretch;
    height: 100vh;
    border-right: 2px solid var(--tertiary-color);
    border-bottom: none;
  }

  .sidebar-tab {
    margin-bottom: 10px;
  }

  .chat-main {
    flex: 0.8;
    padding: 40px;
  }
}

@media (min-width: 1440px) {
  .chat-main {
    padding: 60px;
    flex: 0.85;
  }
}

/* Animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
