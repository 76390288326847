/* chatdisplay.css */
.chat-display-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #ffffff;
}

.chat-display-container .chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-display-container .chat-input {
  padding: 10px;
  background-color: #f7f7f7;
  border-top: 1px solid #ddd;
}
