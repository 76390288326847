:root {
  --primary-color: #6a1b9a;
  --secondary-color: #f06699;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
  --dark-color: #333;
  --text-muted: #797777;
  --font-family: 'Readex Pro', sans-serif;
  --font-size-base: 1rem;
  --font-size-large: 1.5rem;
  --font-size-small: 0.875rem;
  --border-radius: 10px;
  --transition: 0.3s ease;
}

body {
  font-family: var(--font-family);
  margin: 0;
  padding: 0;
  background-color: var(--light-color);
  color: var(--dark-color);
}

.support-page {
  padding: 4rem 2rem 2rem;
}

.support-header {
  text-align: center;
  margin-bottom: 2rem;
}

.support-header h1 {
  font-size: var(--font-size-large);
  color: var(--primary-color);
}

.support-header p {
  font-size: var(--font-size-base);
  color: var(--text-muted);
  max-width: 600px;
  margin: 0 auto 1rem;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.search-bar input {
  width: 60%;
  padding: 0.5rem;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  font-size: var(--font-size-base);
}

.search-bar button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition);
}

.search-bar button:hover {
  background-color: var(--secondary-color);
}

.support-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.support-card {
  background-color: var(--light-color);
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  padding: 1.5rem;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.support-card h2 {
  font-size: var(--font-size-large);
  color: var(--secondary-color);
}

.support-card p {
  font-size: var(--font-size-base);
}

.support-card a {
  color: var(--tertiary-color);
  font-weight: bold;
}

.support-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.support-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--tertiary-color);
}

.extra-content {
  margin-top: 3rem;
}

.testimonials,
.quick-tips {
  margin-bottom: 2rem;
}

blockquote {
  font-style: italic;
  color: var(--dark-color);
  margin: 1rem auto;
  max-width: 500px;
  text-align: center;
}

.quick-tips ul {
  list-style: none;
  padding: 0;
  color: var(--dark-color);
}

.quick-tips li {
  margin: 0.5rem 0;
}

footer a {
  color: var(--primary-color);
}

footer a:hover {
  text-decoration: underline;
}
