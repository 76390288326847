:root {
  --primary-color: #b799c5;
  --secondary-color: #f06699;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
}

/* Chat Input Container */
.chat-input {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--light-color);
  border-top: 2px solid var(--primary-color);
  padding: 15px 20px;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 12px 12px;
  position: sticky;
  bottom: 0;
  z-index: 10;
}

/* Text Area */
.chat-textarea {
  flex: 1;
  resize: none;
  height: 50px;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  color: var(--primary-color);
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.chat-textarea:focus {
  border-color: var(--tertiary-color);
  outline: none;
  box-shadow: 0 0 4px var(--tertiary-color);
}

.chat-textarea::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Send Button */
.send-button {
  background-color: var(--secondary-color);
  color: var(--light-color);
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.send-button:hover {
  background-color: var(--tertiary-color);
  transform: translateY(-2px);
}

.send-button:disabled {
  background-color: rgba(240, 102, 153, 0.5);
  cursor: not-allowed;
}

/* Error Message */
.error-message {
  color: var(--secondary-color);
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 8px;
  text-align: center;
}
