/* ==========================================================================
   Root Variables
   ========================================================================== */
:root {
  --primary-color: #5f2f5f;
  --secondary-color: #fc99f3;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
  --dark-color: #333;
  --text-muted: #797777;
  --font-family: 'Readex Pro', sans-serif;
  --font-size-base: 1rem;
  --font-size-large: 1.5rem;
  --font-size-small: 0.875rem;
  --border-radius: 10px;
  --transition: 0.3s ease;
}
/* ==========================================================================
   Global Styles
   ========================================================================== */
html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--light-color);
  color: var(--dark-color);
  text-align: center;
}

h1,
h2,
h3 {
  margin: 0.5em 0;
}

h2 {
  font-style: italic;
}

button,
input[type='submit'] {
  cursor: pointer;
}

/* ==========================================================================
   Buttons
   ========================================================================== */
.primary-button {
  color: #fff;
  text-transform: uppercase;
  font-size: var(--font-size-base);
  background: linear-gradient(
    45deg,
    var(--primary-color),
    var(--secondary-color)
  );
  padding: 0.75rem 1.5rem;
  border-radius: 1.875rem;
  border: none;
  margin-top: 1rem;
  transition: var(--transition);
}

.primary-button:hover {
  background: linear-gradient(
    260deg,
    var(--primary-color),
    var(--secondary-color)
  );
}

.secondary-button {
  align-self: flex-end;
  padding: 10px 16px;
  font-size: 16px;
  background-color: var(--tertiary-color);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.secondary-button:hover {
  color: var(--dark-color);
}

.secondary-button:disabled {
  background-color: #cfcfcf;
  cursor: not-allowed;
}

/* ==========================================================================
   Overlay
   ========================================================================== */
.overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url('images/banner.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ==========================================================================
   Auth Modal
   ========================================================================== */
.auth-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it covers everything */
}

.auth-modal {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  width: 90%; /* Responsive design for smaller screens */
  text-align: center;
  position: relative;
}

.auth-modal .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.auth-modal .close-icon:hover {
  color: #d9534f;
}

.auth-modal h2 {
  margin-bottom: 15px;
  color: #333;
}

.auth-modal p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.auth-modal form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-modal form label {
  text-align: left;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.auth-modal form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.auth-modal form input:focus {
  border-color: var(--primary-color);
}

.auth-modal form .primary-button {
  background: var(--primary-color);
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.auth-modal form .primary-button:hover {
  background: #52b1a4;
}

.auth-modal .error-message {
  color: var(--primary-color);
  font-size: 14px;
}

.auth-modal hr {
  margin: 20px 0;
  border: none;
  border-top: 1px solid #ddd;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .matches-display {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .match-card {
    width: 100px;
  }

  .match-info h3 {
    font-size: 0.9rem;
  }

  .match-info p {
    font-size: 0.75rem;
  }
}

/* -------- Responsive Design -------- */
@media (max-width: 1024px) {
  .dashboard {
    flex-direction: column;
    align-items: center;
    padding: 5rem 1rem 2rem 1rem;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .dashboard {
    padding: 5rem 1rem 2rem 1rem;
  }

  .logo-container {
    flex: none;
  }
}
