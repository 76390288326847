:root {
  --primary-color: #b799c5;
  --secondary-color: #f06699;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: var(--light-color);
}

.swipe-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 5rem; /* Prevent overlap with header */
  background-color: var(--primary-color);
  min-height: 100vh;
  box-sizing: border-box;
}

.welcome-text {
  text-align: center;
  color: var(--light-color);
  margin-bottom: 1rem;
}

.welcome-text h2 {
  font-size: 2rem;
  font-weight: bold;
}

.welcome-text p {
  font-size: 1.2rem;
}

.card-container {
  position: relative;
  width: 90%;
  max-width: 400px;
  height: 60vh;
  margin-top: 1rem;
}

.card {
  background: white;
  border: 2px solid var(--secondary-color);
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: absolute;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  text-align: center;
  z-index: 1;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.profile-pic {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-bottom: 3px solid var(--tertiary-color);
}

.profile-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--tertiary-color);
  margin: 1rem 0;
}

.no-more-profiles {
  text-align: center;
  color: var(--light-color);
  margin-top: 2rem;
}

.no-more-profiles h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.no-more-profiles p {
  font-size: 1rem;
}

.last-action {
  font-size: 1rem;
  font-weight: bold;
  color: var(--secondary-color);
  text-align: center;
  margin-top: 1rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--primary-color);
}

.spinner {
  border: 8px solid var(--light-color);
  border-top: 8px solid var(--secondary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--secondary-color);
  color: var(--light-color);
  z-index: 10;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header .profile {
  display: flex;
  align-items: center;
}

.chat-header .profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

.chat-header .profile h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--light-color);
}

.chat-header .logout-btn {
  background-color: var(--tertiary-color);
  border: none;
  color: var(--light-color);
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.chat-header .logout-btn:hover {
  background-color: var(--secondary-color);
}

/* Responsive Design */
@media screen and (max-width: 600px) {
  .card-container {
    height: 50vh;
  }

  .profile-name {
    font-size: 1.5rem;
  }

  .welcome-text h2 {
    font-size: 1.8rem;
  }

  .welcome-text p {
    font-size: 1rem;
  }

  .spinner {
    width: 40px;
    height: 40px;
  }
}
