/*-------- OnBoarding Page --------*/
.onboarding {
  border-top: solid 1px #d5d5d5;
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
}

.onboarding h2 {
  text-align: center;
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.onboarding form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.onboarding form section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.onboarding form label {
  margin: 10px 0 5px;
  font-size: 14px;
  color: #555;
  font-weight: bold;
}

.onboarding form input {
  padding: 12px 15px;
  margin: 5px 0 15px;
  font-size: 14px;
  border: solid 1px #d7d7d7;
  border-radius: 8px;
  background-color: #fdfdfd;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.onboarding form input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 3px rgba(221, 39, 34, 0.2);
  outline: none;
}

.onboarding form .multiple-input-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.onboarding form input::-webkit-outer-spin-button,
.onboarding form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.onboarding form .multiple-input-container input {
  flex: 1;
}

.onboarding form input[type='radio'] {
  display: none;
}

.onboarding form input[type='radio'] + label {
  padding: 8px 15px;
  border: solid 1px #909090;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}

.onboarding form input[type='radio']:checked + label {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.onboarding form input[type='checkbox'] {
  margin-right: 8px;
}

.onboarding form input[type='submit'] {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.onboarding form input[type='submit']:hover {
  background-color: var(--primary-color);
}

.onboarding form input[type='submit']:active {
  transform: scale(0.98);
}

.onboarding form .photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.onboarding form .photo-container img {
  width: 100%;
  max-width: 200px;
  border-radius: 50%;
  border: 3px solid #ddd;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

@media (max-width: 768px) {
  .onboarding form section {
    width: 90%;
  }

  .onboarding form input[type='number'] {
    width: auto;
  }
}

.onboarding form label.inline {
  display: inline-block;
  margin-right: 10px;
}

.onboarding form input[type='checkbox'].inline {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.onboarding form input[type='number']::invalid {
  border-color: red;
}

/* Ensure the content is visible and not hidden under the navbar */
.onboarding {
  margin-top: 80px; /* Adjust based on navbar height */
  padding: 20px;
}

/* Prevent content from cutting off on the right */
.onboarding form {
  max-width: 100%;
  padding: 15px;
}

/* Enhance responsiveness for smaller screens */
@media (max-width: 768px) {
  .onboarding form {
    padding: 10px;
  }

  .onboarding form section {
    width: 100%;
    margin: 0 auto;
  }

  .onboarding form .multiple-input-container {
    flex-direction: column;
    gap: 5px;
  }
}

@media (max-width: 480px) {
  .onboarding {
    padding: 10px;
  }

  .onboarding h2 {
    font-size: 1.5em;
  }

  .onboarding form input[type='submit'] {
    font-size: 14px;
    padding: 10px;
  }

  .onboarding form label,
  .onboarding form input {
    font-size: 12px;
  }
}

/* Improve hover effects and UI interactivity */
.onboarding form input[type='submit']:hover {
  background-color: #dd2722;
  transform: scale(1.02);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.onboarding form input[type='submit']:active {
  transform: scale(0.96);
  box-shadow: none;
}
