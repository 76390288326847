:root {
  --primary-color: #6a1b9a;
  --secondary-color: #f06699;
  --tertiary-color: #52b1a4;
  --light-color: #fff8f0;
  --dark-color: #333;
  --text-muted: #797777;
  --font-family: 'Readex Pro', sans-serif;
  --font-size-base: 1rem;
  --font-size-large: 1.5rem;
  --font-size-small: 0.875rem;
  --border-radius: 10px;
  --transition: 0.3s ease;
}

body {
  font-family: var(--font-family);
  background-color: var(--light-color);
  color: var(--dark-color);
}

.safety-page {
  padding: 2rem;
  margin-top: 60px; /* Prevent overlap with header */
}

.safety-header {
  text-align: center;
  background-color: var(--primary-color);
  color: var(--light-color);
  padding: 2rem;
  border-radius: var(--border-radius);
}

.safety-header h1 {
  font-size: var(--font-size-large);
  margin-bottom: 0.5rem;
}

.safety-header p {
  font-size: var(--font-size-base);
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

.safety-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;
}

.safety-card {
  background-color: var(--light-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  width: 300px;
  transition: var(--transition);
}

.safety-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.safety-card h2 {
  font-size: var(--font-size-large);
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.safety-card p {
  font-size: var(--font-size-base);
  color: var(--dark-color);
}

.safety-footer {
  text-align: center;
  background-color: var(--tertiary-color);
  color: var(--light-color);
  padding: 1.5rem;
  margin-top: 2rem;
  border-radius: var(--border-radius);
}

.safety-footer p {
  font-size: var(--font-size-small);
}

.safety-footer a {
  color: var(--secondary-color);
  text-decoration: none;
  font-weight: bold;
}

.safety-footer a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .safety-content {
    flex-direction: column;
    align-items: center;
  }

  .safety-card {
    width: 90%;
  }

  .safety-header h1 {
    font-size: 1.2rem;
  }

  .safety-header p {
    font-size: var(--font-size-small);
  }
}

@media (max-width: 480px) {
  .safety-card {
    padding: 1rem;
  }

  .safety-header h1 {
    font-size: 1rem;
  }

  .safety-header p {
    font-size: var(--font-size-small);
  }

  .safety-footer {
    font-size: 0.8rem;
  }
}
