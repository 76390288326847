/* matchesdisplay.css */
.matches-display {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
}

.match-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.match-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.match-img-container {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 50%;
  overflow: hidden;
}

.match-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.match-info {
  text-align: center;
}

.match-info h3 {
  margin: 0;
  font-size: 1.2em;
}

.match-info p {
  color: #888;
  font-size: 0.9em;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loading-container .spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #ddd;
  border-top-color: #6200ea;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.no-matches {
  text-align: center;
  color: #888;
  font-size: 1em;
}
