/* chat.css */
.chat-display {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  overflow-y: auto;
  max-height: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.chat-message:hover {
  transform: scale(1.02);
}

.chat-message-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #6200ea;
}

.chat-message-text {
  font-size: 1rem;
  color: #333333;
  background-color: #f2f2f2;
  padding: 8px 12px;
  border-radius: 6px;
  max-width: 80%;
  word-wrap: break-word;
}

.no-messages {
  text-align: center;
  color: #888888;
  font-size: 1.2rem;
  padding: 20px;
}

/* Adjustments for larger screens */
@media (min-width: 1024px) {
  .chat-message {
    padding: 15px;
    gap: 15px;
  }

  .chat-message-text {
    font-size: 1.1rem;
  }

  .img-container {
    width: 50px;
    height: 50px;
  }
}
