/* Chat Header */
.chat-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  box-sizing: border-box;
}

/* Profile Section */
.profile {
  display: flex;
  align-items: center;
}

.img-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: var(--font-size-base);
  font-weight: 600;
  color: var(--light-color);
}

/* Navigation Menu */
.navigation-menu {
  display: flex;
  gap: 15px;
}

.navigate-btn {
  background-color: var(--secondary-color);
  color: var(--light-color);
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 15px;
  font-size: var(--font-size-base);
  cursor: pointer;
  transition: background-color var(--transition);
}

.navigate-btn:hover {
  background-color: var(--tertiary-color);
}

.logout-btn {
  background-color: var(--secondary-color);
  color: var(--light-color);
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 15px;
  font-size: var(--font-size-base);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color var(--transition);
}

.logout-btn:hover {
  background-color: var(--tertiary-color);
}

.logout-icon {
  font-size: 1.2rem;
}

/* Hamburger Menu */
.hamburger-menu {
  display: none;
  font-size: 1.5rem;
  color: var(--light-color);
  cursor: pointer;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .navigation-menu {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: var(--primary-color);
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
    display: none;
    z-index: 1000;
  }

  .navigation-menu.open {
    display: flex;
  }

  .hamburger-menu {
    display: block;
  }

  .navigate-btn,
  .logout-btn {
    width: 80%;
    text-align: center;
  }
}
