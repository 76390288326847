/* General Navbar Layout */
nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
}

/* Logo Container */
.logo-container {
  flex: 1;
}

.logo {
  max-width: 120px;
  height: auto;
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: var(--font-size-base);
  transition: color var(--transition);
}

.nav-links a:hover {
  color: var(--tertiary-color);
}

/* Buttons */
.nav-button {
  padding: 0.5rem 1.2rem;
  font-size: var(--font-size-base);
  color: var(--primary-color);
  background-color: #fff;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition: all var(--transition);
}

.nav-button:hover {
  background-color: var(--tertiary-color);
  color: #fff;
}

/* Spinner Animation */
.spinner {
  animation: spin 1s linear infinite;
  font-size: 1.2rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

/* Mobile Menu */
.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 2rem;
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  padding: 1rem;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity var(--transition), transform var(--transition);
  z-index: 1000;
}

.mobile-menu.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

.mobile-menu a,
.mobile-menu .secondary-button {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: var(--font-size-base);
}

.mobile-menu a:hover,
.mobile-menu .secondary-button:hover {
  color: var(--tertiary-color);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide desktop links on small screens */
  }

  .hamburger {
    display: flex; /* Show hamburger menu */
  }
}
